import React from 'react'
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import { images } from '../../constants';

import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className="app__header app__flex">

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles-left"
      >

        {[images.html, images.javascript, images.css].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
        
      </motion.div>

      <motion.div
        whileInView={{ y: [-150, 0], opacity: [0, 1] }}
        transition={{ duration: 0.75 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div>
                <h1 className="greeting-text home-text">Hello!</h1>
                <h2 className="introduction-text home-text">My name is Dominik</h2>
                <p className="introduction-description-text home-text">I am a Software developer who specializes in front-end development!</p>
                <a className="contact-button" href="mailto:dominikmachowiak101@gmail.com" >Contact Me</a>
          </div>
        </div>
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles-right"
      >

        {[images.python, images.csharp, images.react].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
        
      </motion.div>

    </div>
  )
}

export default AppWrap(Header, 'home', 'app__darkbg');