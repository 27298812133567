import React from 'react'
import { BsMailbox2 } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href="mailto:dominikmachowiak101@gmail.com" target="_blank" rel="noreferrer noopener"><BsMailbox2 /></a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/dominikmachowiak/" target="_blank" rel="noreferrer noopener"><FaLinkedinIn /></a>
        </div>
    </div>
  )
}

export default SocialMedia